

.top-menu{
    width: 32px;
    background: white;
    height: 3px;
    position: absolute;
    top: 0;
    transform: translateY(0%);
    left: 0;
}

.middle-menu{
    width: 20px;
    background: white;
    height: 3px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
}

.top-menu-click{
    animation: animateTopNav .5s 1;
}

.top-menu-click-reverse{
    animation: animateTopNavReverse .5s 1;
}

.top-menu-clicked{
    transform: rotate(45deg) translate(-50%,-50%);
    top: 70%;
    left: 45%;
    height: 5px;
    border-radius: 5px;
}

@keyframes animateTopNav {
    0%{
        
    }
    100%{
        transform: rotate(45deg) translate(-50%,-50%);
        top: 70%;
        left: 45%;
        height: 5px;
        border-radius: 5px;
    }
}
@keyframes animateTopNavReverse {
    0%{
        transform: rotate(45deg) translate(-50%,-50%);
        top: 70%;
        left: 50%;
        
    }
    100%{
        top: 0;
        left: 0;
        transform: translate(0);
    }
}



.middle-menu-click{
    animation: animateMiddleNav .5s 1;
}
.middle-menu-clicked{
    opacity: 0;
    left: -100%;
}
@keyframes animateMiddleNav {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        left: -100%;
    }
}





.bottom-menu{
    width: 24px;
    background: rgb(234 179 8);
    height: 3px;
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    left: 0;
}

.bottom-menu-click{
    animation: animateBottomNav .5s 1;
}

.bottom-menu-click-reverse{
    animation: animateBottomNavReverse .5s 1;
}

.bottom-menu-clicked{
    transform: rotate(-45deg) translate(-50%,-50%);
    top: -20%;
    left: 50%;
    width: 32px;
    height: 5px;
    border-radius: 5px;
}

@keyframes animateBottomNav {
    0%{
        
    }
    100%{
        width: 32px;
        transform: rotate(-45deg) translate(-50%,-50%);
        top: -20%;
        left: 50%;
        height: 5px;
        border-radius: 5px;
    }
}

@keyframes animateBottomNavReverse {
    0%{
        transform: rotate(-45deg) translate(-50%,-50%);
        top: -20%;
        left: 50%;
        height: 5px;
    }
    100%{
        top: 100%;
        left: 0;
        transform: translateY(-50%);
    }
}