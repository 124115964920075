@tailwind base;
@tailwind components;
@tailwind utilities;

:root { 
  --scale-on-mobile: 1.2;
}

*{
  transition: .3s;
}

.transition-1s{
  transition: 1s !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  scroll-behavior: smooth;
  background: black;
}
.parent-container{
  background: linear-gradient( rgb(2, 9, 24), black, rgb(0, 0, 21), rgb(21, 0, 19), rgb(31, 31, 31));
}
.parent-container-2{
  background: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}



.glowing-white{
  box-shadow: 0px 0px 25px 10px rgba(255, 255, 255, 0.669) !important;
  background: linear-gradient(to right, rgb(51 65 85), rgb(51 65 85));
}
.glowing-link:hover .glowing-white{
  box-shadow: none !important;
  background: none;
}

.glowing-link:hover .glowing-link-arrow{
  transition-duration: 1.10s;
  animation: 0.4s infinite ease glowingLinkArrowAnim;
}

@keyframes glowingLinkArrowAnim {
  0%{
    transform: translateX(0);
  }
  30%{
    transform: translateX(3px);
  }
  85%{
    transform: translateX(6px);
  }
  100%{
    transform: translateX(0px);
  }
}



/* BOTTOM HOMEPAGE ANIMATION */

.base{
  animation: homeBottomAnimation 3s linear infinite;
}
@keyframes homeBottomAnimation{
  0%{
    top: 10px;
    left: 5px;
  }
  50%{
    top: 0;
    left: 0;
  }
  100%{
    top: 10px;
    left: 5px;
  }
}
#bottom-anim-bg {
  transition: 2s;
}
.anim-image{
  position: absolute;
  transition: 2s;
  transform: scale(1.8);
}
.maspeqa-blue{
  top: -180%;
  left: 0%;
  z-index: 60;
}
.maspeqa-accent{
  width: 76% !important;
  top: -110%;
  left: -40%;
  z-index: 50;
}
.maspeqa-white{
  width: 76% !important;
  top: -110%;
  right: -40%;
  z-index: 50;
}

.anim-text{
  /* width: 560px; */
  top: -30%;
}



.maspeqa-blue-1{
  top: -105%;
  left: 0%;
  transform: translateX(-50%);
  z-index: 60;
  transform: scale(1.7);
}
.maspeqa-accent-1{
  width: 76% !important;
  top: -65%;
  left: -2%;
  z-index: 50;
}

.maspeqa-white-1{
  width: 76% !important;
  top: -65%;
  right: -2%;
  z-index: 50;
}

.anim-text-1{
  /* width: 560px; */
  top: -52%;
}

.bottom-anim-bg{
  width: 100%;
  margin-top: 50px;
}

.bottom-anim-bg-1{
  width: 100%;
  margin-top: -10px;
}

@media only screen and (max-width: 500px) {
  .anim-image {
    /* transform: scale(var(--scale-on-mobile)); */
  }
  .maspeqa-blue{
    top: -130%;
  }
  .maspeqa-accent, .maspeqa-white{
    top: -80%;
  }
}

.animated-bg{
  animation: animatedBgAnim 10s linear infinite;
}

@keyframes animatedBgAnim {
  0% {
      /* Left unoccupied as requested */
  }
  20%, 100% {
      transform: translate(5px, -2px) scale(1.01);
  }
  40% {
      transform: translate(2px, 3px) scale(1.005);
  }
  60% {
      transform: translate(-3px, 1px) scale(1);
  }
  80% {
      transform: translate(0px, -4px) scale(1.005);
  }
}

.animated-bg-1 {
  animation: animatedBgAnim1 10s linear infinite;
}

@keyframes animatedBgAnim1 {
  0% {
      /* Left unoccupied as requested */
  }
  13%, 100% {
      transform: translate(-3px, -1px) scale(1);
  }
  30% {
      transform: translate(2px, 3px) scale(1.005);
  }
  49% {
      transform: translate(4px, -2px) scale(1.01);
  }
  78% {
      transform: translate(-1px, 2px) scale(1.005);
  }
}

.animated-bg-2 {
  animation: animatedBgAnim2 10s linear infinite;
}

@keyframes animatedBgAnim2 {
  0% {
      /* Left unoccupied as requested */
  }
  20%, 100% {
      transform: translate(-1px, -3px) scale(1);
  }
  40% {
      transform: translate(3px, 1px) scale(1.005);
  }
  60% {
      transform: translate(-2px, 2px) scale(1.01);
  }
  80% {
      transform: translate(1px, -2px) scale(1.005);
  }
}

.animated-bg-3 {
  animation: animatedBgAnim3 10s linear infinite;
}

@keyframes animatedBgAnim3 {
  0% {
      /* Left unoccupied as requested */
  }
  25%, 100% {
      transform: translate(-2px, -1px) scale(1);
  }
  45% {
      transform: translate(1px, 3px) scale(1.005);
  }
  65% {
      transform: translate(3px, -2px) scale(1.01);
  }
  85% {
      transform: translate(-3px, 1px) scale(1.005);
  }
}

.animated-bg-4 {
  animation: animatedBgAnim4 10s linear infinite;
}

@keyframes animatedBgAnim4 {
  0% {
      /* Left unoccupied as requested */
  }
  23%, 100% {
      transform: translate(-4px, 1px) scale(1);
  }
  36% {
      transform: translate(-1px, -2px) scale(1.005);
  }
  70% {
      transform: translate(-3px, 2px) scale(1.01);
  }
  89% {
      transform: translate(5px, -3px) scale(1.005);
  }
}


.pricing-banner{
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1) 40%, rgba(255, 185, 24, 0.44));
}

.gradient-home-text {
  background: linear-gradient(to bottom, rgb(5, 115, 119) 20%, rgb(0, 20, 20) 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.gradient-pricing-text {
  background: linear-gradient(to bottom, blue 50%, violet);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.spotlight-image {
  mask-image: radial-gradient( transparent, black 50%, black 4%, black 0%, transparent);
  /* -webkit-mask-image: linear-gradient(to right, transparent, black 20%, black 80%, transparent);  */
}

.dialog-paper {
  background-color: transparent !important;
}

.blurhash-pulse{
  animation: blurhashPulse 1s linear infinite;
}

@keyframes blurhashPulse {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.8;
  }
  100%{
    opacity: 1;
  }
}




/* CALENDRY CSS */




.calendly-inline-widget,
.calendly-inline-widget *,
.calendly-badge-widget,
.calendly-badge-widget *,
.calendly-overlay,
.calendly-overlay * {
    font-size: 16px;
    line-height: 1.2em;
}

.calendly-inline-widget iframe,
.calendly-badge-widget iframe,
.calendly-overlay iframe {
    display: inline;
    width: 100%;
    height: 100%;
}

.calendly-popup-content {
    position: relative;
}

.calendly-popup-content.calendly-mobile {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.calendly-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 9999;
    background-color: #a5a5a5;
    background-color: rgba(31, 31, 31, 0.4);
}

.calendly-overlay .calendly-close-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.calendly-overlay .calendly-popup {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 80%;
    min-width: 900px;
    max-width: 1000px;
    height: 90%;
    max-height: 680px;
}

@media (max-width: 975px) {
    .calendly-overlay .calendly-popup {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        width: 100%;
        height: auto;
        min-width: 0;
        max-height: none;
    }
}

.calendly-overlay .calendly-popup .calendly-popup-content {
    height: 100%;
}

.calendly-overlay .calendly-popup-close {
    position: absolute;
    top: 25px;
    right: 25px;
    color: #fff;
    width: 19px;
    height: 19px;
    cursor: pointer;
    background: url(https://assets.calendly.com/assets/external/close-icon.svg) no-repeat;
    background-size: contain;
}

@media (max-width: 975px) {
    .calendly-overlay .calendly-popup-close {
        top: 15px;
        right: 15px;
    }
}

.calendly-badge-widget {
    position: fixed;
    right: 20px;
    bottom: 15px;
    z-index: 9998;
}

.calendly-badge-widget .calendly-badge-content {
    display: table-cell;
    width: auto;
    height: 45px;
    padding: 0 30px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
    font-family: sans-serif;
    text-align: center;
    vertical-align: middle;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.calendly-badge-widget .calendly-badge-content.calendly-white {
    color: #666a73;
}

.calendly-badge-widget .calendly-badge-content span {
    display: block;
    font-size: 12px;
}

.calendly-spinner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    text-align: center;
    z-index: -1;
}

.calendly-spinner > div {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #e1e1e1;
    border-radius: 50%;
    vertical-align: middle;
    animation: calendly-bouncedelay 1.4s infinite linear;
    animation-fill-mode: both;
}

.calendly-spinner .calendly-bounce1 {
    animation-delay: -0.32s;
}

.calendly-spinner .calendly-bounce2 {
    animation-delay: -0.16s;
}

@keyframes calendly-bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}






.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}
.my-masonry-grid_column1 {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column1 > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}
.centered-image {
  transition: transform 1s ease; /* Transition the transform property over 1 second with ease */
}

.centered-image:hover {
  transform: scale(1.2); /* Optional: Increase size slightly on hover for visual feedback */
}